import React from 'react'
import { AllPodsTable } from './Table/AllPodsTable'
import { NetworkStatus } from '@apollo/client'
import styles from './AllPodsPage.module.scss'
import { defaultSort } from './useDisplaySearchOptions'
import { StatusCard } from './Header/StatusCard'
import { SolPodActions } from 'SolComponents/SolDropdown/SolPodActions/SolPodActions'
import { PodActionsRide } from './PodActionsRide'
import { useAllPods } from './useAllPods'
import SolFilterHeaderCard from 'SolComponents/SolFilterHeaderCardWithSideFilters/SolFilterHeaderCard'
import { DataTableInstance } from 'components/DataTableSlim/DataTableSlim'
import ContentWrapper from 'components/ContentWrapper/ContentWrapper'
import { TaskType } from 'graphql/__generated__/types'

export default React.memo(() => {
  const {
    numSelectedItems,
    tableData,
    totalItems,
    onSelect,
    refetchDisplays,
    loading,
    initialLoadHappened,
    networkStatus,
    selectedItems,
    displaySearchOptions,
    totalSelectableItems,
    loadingTotalSelectedItems,
    selectedPod,
    selectedItemsIds,
    selectedFailedOnboardingItemsIds,
  } = useAllPods(true)

  return (
    <div>
      <SolFilterHeaderCard
        header="All Pods"
        filteredItems={numSelectedItems}
        tableId={DataTableInstance.AllPods}
        getTotalPods={() => totalItems ?? 0}
      />
      <ContentWrapper>
        <div className={styles.topRow}>
          <StatusCard />
          <PodActionsRide />
          <SolPodActions
            totalItemsSelected={numSelectedItems}
            podName={selectedPod?.name}
            taskTypes={[
              TaskType.Reboot,
              TaskType.BootAllUsers,
              TaskType.ClearScreen,
              TaskType.Wake,
              TaskType.Suspend,
              TaskType.ForgetWifiNetworks,
              TaskType.Delete,
            ]}
            isAllPodsPage
          />
        </div>
        <AllPodsTable
          data={tableData}
          totalItems={totalItems}
          totalItemsSelectable={totalSelectableItems}
          loadingTotalSelectedItems={loadingTotalSelectedItems}
          displaySearchOptions={displaySearchOptions}
          totalItemsSelected={numSelectedItems}
          onSelect={onSelect}
          refetchDisplays={refetchDisplays}
          selectedItems={{
            includedIds: selectedItems.includedIds,
            excludedIds: selectedItems.excludedIds,
          }}
          defaultSort={defaultSort}
          loading={
            (loading || !initialLoadHappened)
            && networkStatus !== NetworkStatus.poll
          }
          selectedItemsIds={selectedItemsIds}
          selectedFailedOnboardingItemsIds={selectedFailedOnboardingItemsIds}
        />
      </ContentWrapper>
    </div>
  )
})
